export const columns = [
  {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      /*width:150,*/
      align:'center',
      ellipsis:true,
      scopedSlots: { customRender: 'index'}
  },
  {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 200,
    ellipsis: true,
  },
  {
    title: '是否保留小数点',
    dataIndex: 'flagPoint',
    key: 'flagPoint',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'flagPoint'}
  },
  {
    title: '小数点位数',
    dataIndex: 'point',
    key: 'point',
    /*width:150,*/
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'point'}
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  }
]
